<template>
  <div>
    <loading v-if="$apollo.loading" />
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-if="sensor.name === 'Tilt'"
              class="text-center text-uppercase"
              @click="changeOrder('PITCH')"
            >
              PITCH
              <sorting-arrows :direction="column === 'PITCH' ? order : null"/>
            </th>
            <th
              v-if="sensor.name === 'Tilt'"
              class="text-center text-uppercase"
              @click="changeOrder('ROLL')"
            >
              ROLL
              <sorting-arrows :direction="column === 'ROLL' ? order : null"/>
            </th>
            <th
              v-if="sensor.name === 'Air Quality'"
              class="text-center text-uppercase"
              @click="changeOrder('PM1')"
            >
              PM1
              <sorting-arrows :direction="column === 'PM1' ? order : null"/>
            </th>
            <th
              v-if="sensor.name === 'Air Quality'"
              class="text-center text-uppercase"
              @click="changeOrder('PM2')"
            >
              PM2.5
              <sorting-arrows :direction="column === 'PM2' ? order : null"/>
            </th>
            <th
              v-if="sensor.name === 'Air Quality'"
              class="text-center text-uppercase"
              @click="changeOrder('PM4')"
            >
              PM4
              <sorting-arrows :direction="column === 'PM4' ? order : null"/>
            </th>
            <th
              v-if="sensor.name === 'Air Quality'"
              class="text-center text-uppercase"
              @click="changeOrder('PM10')"
            >
              PM10
              <sorting-arrows :direction="column === 'PM10' ? order : null"/>
            </th>
            <th
              v-if="sensor.name !== 'Air Quality' && sensor.name !== 'Tilt'"
              class="text-center text-uppercase"
              @click="changeOrder('VALUE')"
            >
              Value
              <sorting-arrows :direction="column === 'VALUE' ? order : null"/>
            </th>
            <th
                @click="changeOrder('LAST_UPDATE')"
              class="text-center text-uppercase"
            >
              Last Update
              <sorting-arrows :direction="column === 'LAST_UPDATE' ? order : null"/>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-if="sensorData && sensorData.length == 0">
            <td class="text-center">
              <h3>
                <v-icon size="20">
                  {{ icons.mdiCancel }}
                </v-icon>
                No items
              </h3>
            </td>
          </tr>
          <tr
            v-else
            v-for="item in sensorData"
            :key="item.id"
          >
            <td
                v-if="sensor.name === 'Tilt'"
                class="text-center"
            >
              {{ JSON.parse(item.value)['pitch'] }} {{sensor.measuring_unit}}
            </td>
            <td
                v-if="sensor.name === 'Tilt'"
                class="text-center"
            >
              {{ JSON.parse(item.value)['roll'] }} {{sensor.measuring_unit}}
            </td>
            <td
                v-if="sensor.name === 'Air Quality'"
                class="text-center"
            >
              {{ JSON.parse(item.value)[0] }} {{sensor.measuring_unit}}
            </td>
            <td
                v-if="sensor.name === 'Air Quality'"
                class="text-center"
            >
              {{ JSON.parse(item.value)[1] }} {{sensor.measuring_unit}}
            </td>
            <td
                v-if="sensor.name === 'Air Quality'"
                class="text-center"
            >
              {{ JSON.parse(item.value)[2] }} {{sensor.measuring_unit}}
            </td>
            <td
                v-if="sensor.name === 'Air Quality'"
                class="text-center"
            >
              {{ JSON.parse(item.value)[3] }} {{sensor.measuring_unit}}
            </td>

            <td
                class="text-center"
                v-if="sensor.name !== 'Air Quality' && sensor.name !== 'Tilt'"
            >
              <div v-if="sensor.name === 'GPS'">
                <v-btn
                  plain
                  @click="openGoogleMap(item.value)"
                >
                  {{ JSON.parse(item.value)['lat']}}, {{ JSON.parse(item.value)['long'] }}
                </v-btn>
              </div>
              <div v-else>
                {{ getLastValue(item.value) }}
              </div>
            </td>
            <td class="text-center">
              {{ moment.unix(item.last_update).format("YYYY-MM-DD HH:mm") }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <pagination
      v-if="sensorData && countPages"
      :count="countPages"
      :page.sync="currentPage"
    />
  </div>
</template>

<script>
/* eslint-disable */

import { ref } from '@vue/composition-api'
// eslint-disable-next-line object-curly-newline
import Pagination from '@/views/components/pagination/Pagination.vue'
import gql from 'graphql-tag'
import Loading from '../Loading.vue'
import SortingArrows from '../SortingArrows.vue'
import { mdiCancel } from '@mdi/js'

export default {
  name: 'SensorDataTable',
  components: {
    Pagination,
    Loading,
    SortingArrows,
  },
  props: {
    search: {
      required: false,
      default: null,
      type: String,
    },
    sensor: {
      required: true,
      type: Object,
    },
  },
  apollo: {
    sensors_data: {
      query: gql`
        query ($sensor_id: ID!, $page: Int!, $search: String, $orderBy: [QuerySensorsDataOrderByOrderByClause!]) {
          sensors_data(sensor_id: $sensor_id, page: $page, search: $search, orderBy: $orderBy) {
            data {
              last_update
              value
            }
            paginatorInfo {
              currentPage
              lastPage
            }
          }
        }
      `,
      debounce: 350,
      variables() {
        return {
          search: this.search ?? undefined,
          sensor_id: this.$route.params.sensor_id,
          orderBy: [
            {
              column: this.column,
              order: this.order,
            },
          ],
          page: this.currentPage,
        }
      },
      skip() {
        return !this.$route.params.sensor_id
      },
      result(result) {
        this.sensorData = result.data.sensors_data.data
        this.countPages = result.data.sensors_data.paginatorInfo.lastPage
        this.currentPage = result.data.sensors_data.paginatorInfo.currentPage
      },
      errorPolicy: 'all',
      error(e) {
        if (e.networkError) this.$toastr.e('Network Error')
        else this.$toastr.e('Server Error')
      },
    },
  },
  setup(props) {
    const role = localStorage.getItem('userAbility')
    const currentPage = ref(1)
    const countPages = ref(null)
    const searchProp = ref(props.search)
    const column = ref('CREATED_AT')
    const order = ref('DESC')
    const sensorData = ref([])

    return {
      role,
      sensorData,
      currentPage,
      countPages,
      searchProp,
      column,
      order,
      icons: {
        mdiCancel,
      },
    }
  },
  methods: {
    changeOrder(column) {
      this.column = column
      this.order = this.order === 'ASC' ? 'DESC' : 'ASC'
    },
    getLastValue(value) {
      if (this.sensor.name === 'Air Quality') {
        const sensor_value = JSON.parse(value)

        return `${sensor_value[0]}  µg/m³, ${sensor_value[1]} µg/m³, ${sensor_value[2]} µg/m³, ${sensor_value[3]} µg/m³`
      }

      if (this.sensor.name === 'GPS') {
        const sensor_value = JSON.parse(value)

        return `${sensor_value.lat}, ${sensor_value.long}`
      }

      if (this.sensor.name === 'Tilt') {
        const sensor_value = JSON.parse(value)

        return `Pitch: ${sensor_value.pitch}${this.sensor.measuring_unit}, Roll: ${sensor_value.roll}${this.sensor.measuring_unit}`
      }

      if (this.sensor.name === 'Solar Input' || this.sensor.name === 'Solar Input Average')
        value = Math.round(value * 1000) / 1000

      return `${value}${this.sensor.measuring_unit}`
    },
    openGoogleMap(location) {
      const gps = JSON.parse(location)
      const urlSuffix = [gps['lat'], gps['long']]

      // eslint-disable-next-line
      window.open('https://www.google.com/maps/search/?api=1&query=' + urlSuffix, '_blank')
    },
  },
}
</script>
