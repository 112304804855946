<template>
    <v-row class="px-3">
        <h2 class="mb-4"> {{ title }}</h2>
    </v-row>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      required: true,
      default: null,
    },
  },
}
</script>
