<template>
  <div class="my-3">
        <page-title v-if="sensor" :title="getPageTitle" />
        <v-row class="px-3 mb-2">
        <v-btn
            color="primary"
            dark
            class="mr-2 mb-1"
            @click="$router.push(`/devices/${$route.params.id}`)"
        >
            <v-icon size="20">
            {{ icons.mdiArrowLeft }}
            </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    @click="exportData()"
                    color="primary"
                    v-bind="attrs"
                    v-on="on"
                    >
                    <v-icon size="20">
                        {{ icons.mdiFileDownloadOutline  }}
                    </v-icon>
                </v-btn>
            </template>
            <span>Export (.csv)</span>
        </v-tooltip>
    </v-row>

    <sensor-data-table
        v-if="sensor"
        ref="sensorDataTable"
        :search="search"
        :sensor="sensor"
        @change="refetchData"
    />
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiArrowLeft, mdiFileDownloadOutline } from '@mdi/js'
import SensorDataTable from '@/views/components/sensors/SensorDataTable'
import axios from '@axios'
import gql from 'graphql-tag'
import PageTitle from './components/PageTitle.vue'

export default {
  components: {
    SensorDataTable,
    PageTitle,
  },
  apollo: {
    sensor: {
      query: gql`
        query ($id: ID!) {
          sensor(id: $id) {
            name
            measuring_unit
            device {
              id
              id_imei
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.sensor_id,
        }
      },
      skip() {
        return !this.$route.params.sensor_id
      },
      result(result) {
        this.sensor = result.data.sensor
      },
      errorPolicy: 'all',
      error(e) {
        if (e.networkError) this.$toastr.e('Network Error')
        else this.$toastr.e("Server Error")
      },
    },
  },

  setup() {
    const role = localStorage.getItem('userAbility')
    const search = ref('')
    const showModal = ref(false)

    return {
      icons: {
        mdiMagnify,
        mdiArrowLeft,
        mdiFileDownloadOutline,
      },
      role,
      search,
      showModal,
    }
  },

  methods: {
    refetchData() {
      this.$refs.sensorDataTable.getSensorData()
    },
    exportData() {
      axios
        .get('/export', { params: { id: this.$route.params.sensor_id }, responseType: 'blob' })
        .then(response => {
          let filename = ''
          const disposition = response.headers['content-disposition']
          if (disposition && disposition.indexOf('attachment') !== -1) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
            const matches = filenameRegex.exec(disposition)
            if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '')
          }
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = filename
          link.click()
        })
        .catch(error => {
          this.$toastr.e(error)
        })
    },
  },
  computed: {
    getPageTitle() {
      return (
        this.sensor.device.id_imei +
        ' - ' +
        (this.sensor.name === 'Solar Input Average' ? "Solar Input Yesterday's Average" : this.sensor.name)
      )
    },
  },
}
</script>
